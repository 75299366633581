<template>
  <b-card>
    <b-form-group
      :name="data.metaKey"
      :label="data.config.label"
      label-cols-sm="4"
      label-cols-lg="2"
      :class="{ required: data.config.required }"
    >
      <b-form-input
        v-model="data.answer"
        :name="data.metaKey"
        :type="type||'text'"
        v-validate="{
          required: data.config.required ? data.config.required : false,
        }"
        :class="errors.has(`${data.metaKey}`) && 'is-invalid'"
      />
      <b-form-invalid-feedback>
        {{ errors.first(`${data.metaKey}`) }}
      </b-form-invalid-feedback>
    </b-form-group>
  </b-card>
</template>

<script>
export default {
  name: 'form-input',
  props: {
    data: {
      type: Object,
    },
    type: String
  },
  computed: {
    combinedData: {
      get() {
        return this.data.answer
      },
      set(newValue) {
        // console.log(this.data.answer)
        this.data.answer = newValue
        // console.log(this.data.answer)
      },
    },
  },
  methods: {
    async isNotValid() {
      // console.log(this.data.answer)
      let result = await this.$validator.validate()
      return !result
    },
    validateMessage() {
      return '不能為空'
    },
  },
}
</script>
