<template>
  <b-card>
    <b-form-group
      :name="data.metaKey"
      :label="data.config.label"
      label-cols-sm="4"
      label-cols-lg="2"
      :class="{ required: data.config.required }"
    >
      <!-- <b-form-datepicker
        v-model="data.selectedDate"
      ></b-form-datepicker> -->
      <DatePicker
        v-model="data.selectedDate"
        :class="['w-100']"
        :show-today="false"
        format="YYYY-MM-DD"
        size="large"
        :placeholder="`${$t('選擇日期')}`"
      />
    </b-form-group>
  </b-card>
</template>

<script>
import { DatePicker } from 'ant-design-vue'
export default {
  name: 'form-date-picker',
  components: {
    DatePicker
  },
  props: {
    data: {
      type: Object
    }
  },
  computed: {
    combinedSelectedDate: {
      get() {
        return this.data.answer
      },
      set(newValue) {
        // this.data.selectedDate = newValue
        this.data.answer = newValue
      }
    }
  },
  methods: {
    async isNotValid() {
      let result = await this.$validator.validate()
      return !result
    },
    validateMessage() {
      return '必须输入日期'
    }
  }
}
</script>
