<template>
  <b-card>
    <b-form-group :name="data.metaKey" :label="data.config.label" label-cols-sm="4" label-cols-lg="2" :class="{required: data.config.required }">
      <b-form-datepicker
        v-model="data.selectedDate"
      ></b-form-datepicker>
      <b-form-timepicker
        v-model="data.selectedTime"
      ></b-form-timepicker>
    </b-form-group>
  </b-card>
</template>

<script>
export default {
  name: 'form-date-picker',
  props: {
    data: {
      type: Object
    },
    readonly: {
      type: Boolean
    }
  },
  computed: {
    combinedSelectedDate: {
      get() {
        return this.data.answerDate
      },
      set(newValue) {
        // this.data.selectedDate = newValue
        this.data.answerDate = newValue
      }
    },
    combinedSelectedTime: {
      get() {
        return this.data.answerTime
      },
      set(newValue) {
        this.data.answerTime = newValue
      }
    }
  },
  methods: {
    async isNotValid() {
      let result = await this.$validator.validate()
      return !result
    },
    validateMessage() {
      return '必须输入日期'
    }
  }
}
</script>