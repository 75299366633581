<template>
  <div class="dynamic" v-if="isMounted">
    <b-container class="mt-4">
      <h1 class="my-4 ">{{ formData.title }}</h1>
      <b-form>
        <div v-if="formData.metas && formData.metas.length > 0">
          <template v-for="(fitem, index) in formData.metas">
            <template v-if="fitem.type === 'string'">
              <form-input :key="index" :ref="`questions-input-${index}`" :data="fitem"></form-input>
            </template>
            <template v-if="fitem.type === 'text'">
              <form-textarea :key="index" :ref="`questions-textarea-${index}`" :data="fitem"></form-textarea>
            </template>
            <template v-if="fitem.type === 'datetime'">
              <form-time-picker :key="index" :ref="`questions-time-picker-${index}`" :data="fitem"></form-time-picker>
            </template>
            <template v-if="fitem.type === 'select'">
              <form-select :key="index" :ref="`questions-select-${index}`" :data="fitem"></form-select>
            </template>
            <template v-if="fitem.type === 'date'">
              <form-date-picker :key="index" :ref="`questions-date-picker-${index}`" :data="fitem"></form-date-picker>
            </template>
            <template v-if="fitem.type === 'file'">
              <form-attachment :key="index" :ref="`questions-attachment-${index}`" :data="fitem"></form-attachment>
            </template>
            <template v-if="fitem.type === 'boolean'">
              <form-single-checkbox
                :key="index"
                :ref="`questions-single-checkbox-${index}`"
                :data="fitem"
              ></form-single-checkbox>
            </template>
            <template v-if="fitem.type === 'integer'">
              <form-input
                :key="index"
                :ref="`questions-integer-input-${index}`"
                :data="fitem"
                type="number"
              ></form-input>
            </template>
          </template>
          <template v-if="formData.captcha">
            <b-card class="flex-row align-items-start" ref="captcha">
              <b-form-group
                id="fieldset-horizontal"
                label-cols-sm="4"
                label-cols-lg="2"
                :label="$t('驗證碼')"
                class="captcha"
              >
                <b-form-input
                  v-model="formData.identifyCode"
                  type="text"
                  :formatter="formatIdentifyCode"
                  class="col-lg-3 col-12 d-inline-block align-top"
                />
                <div class="col-lg-9 d-inline-block identifyCode align-top">
                  <div class="d-flex" v-if="isMounted">
                    <!-- <SIdentify :identifyCode="identifyCode"></SIdentify> -->
                    <img :src="identifyCode" style="width:150px" />
                    <b-button class="refresh-btn ml-2" @click="refreshCode">{{ $t('按此更新') }} </b-button>
                  </div>
                </div>
              </b-form-group>
            </b-card>
          </template>
        </div>
        <div class="text-center">
          <b-button class="btn btn-primary btn-submit" @click="onSubmit()">{{ $t('提交') }}</b-button>
        </div>
      </b-form>
    </b-container>
    <b-modal id="modal-scoped" centered hide-footer hide-header :no-close-on-backdrop="true">
      <div class="text-center">{{ $t('提交成功') }}!<br />{{ $t('感謝你的填寫') }}</div>
      <div class="text-center">
        <b-button class="w-25 mt-4" @click="handleBack()">{{ $t('返回') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { formData, submit } from '@/api/dynamic_form'
import updateSeoInfo from '@/utils/meta'
import FormInput from './components/Input'
import FormTextarea from './components/Textarea'
import FormSelect from './components/Select'
import FormDatePicker from './components/DatePicker'
import FormTimePicker from './components/TimePicker'
import FormAttachment from './components/Attachment'
import FormSingleCheckbox from './components/SingleCheckbox'
// import SIdentify from './components/SIdentify'
import axios from 'axios'
export default {
  components: {
    FormInput,
    FormTextarea,
    FormSelect,
    FormDatePicker,
    FormTimePicker,
    FormAttachment,
    FormSingleCheckbox
    // SIdentify
  },
  data() {
    return {
      isMounted: false,
      formData: {
        identifyCode: null
      },
      uCode: 'Tai8a8',
      identifyCodes: 'ABCDEFGHIJKLMNOPQRSTUVWYZabcdefghijklmnopqrstuvwyz1234567890',
      identifyCode: '',
      errorMes: null
    }
  },
  serverPrefetch() {
    return this.fetchData()
  },
  methods: {
    async getBase64FromUrl(url) {
      // const data = await fetch(url)
      // const blob = await data.blob()
      // console.log(111, blob)
      const config = { responseType: 'blob' }
      let blob = null
      await axios.get(url, config).then(response => {
        // console.log(response.data)
        blob = response.data
      })
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          resolve(base64data)
        }
      })
    },
    reloadCaptcha() {
      this.getBase64FromUrl('/api/v1/common/captcha?n=' + new Date().getTime()).then(e => {
        this.identifyCode = e
      })
    },
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`dynamic-form/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id, tabsId } = this.$route.query
      // const { preview, vid, id } = route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
            // path: path,
            // id: id,
            // preview: preview,
            // vid: parseInt(vid)
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    hanldeInit() {},
    async onSubmit() {
      let validateResult = false
      for (let ref in this.$refs) {
        if (ref.startsWith('questions-')) {
          let questionComponent = this.$refs[ref][0]
          if (questionComponent && questionComponent.isNotValid !== undefined) {
            let result = await questionComponent.isNotValid()
            if (result) {
              validateResult = result
            }
          }
        }
      }
      if (validateResult) {
        this.errorScoll()
        return
      }

      // if (
      //   this.formData.captcha &&
      //   this.formData.identifyCode != this.identifyCode &&
      //   this.formData.identifyCode != this.uCode
      // ) {
      //   alert(this.$t('驗證碼錯誤'))
      //   return
      // }

      var formId = this.formData.id
      // var data = new Object();
      // for(let item of this.formData.metas) {
      //   var key = item.metaKey
      //   if (item.answer) {
      //     data[key] = item.answer
      //   } else if (item.answerDate || item.answerTime) {
      //     data[key] = item.answerDate + " " + item.answerTime
      //   } else {
      //     data[key] = null
      //   }
      // }

      var data = this.formData.metas
      // const identifyCode = this.formData.identifyCode
      const captcha = this.formData.identifyCode
      const { preview, id } = this.$route.query
      submit({ formId, data, captcha, preview, id }).then(resp => {
        if (resp.code == 0) {
          this.$bvModal.show('modal-scoped')
        } else {
          if (resp.message == 'Captcha Invalid') {
            this.errorMes = this.$t('Captcha Invalid')
            this.captcha = true
            this.reloadCaptcha()
          } else {
            this.errorMes = this.$t('系統繁忙，請稍候再試')
          }
          alert(this.errorMes)
        }
      })
    },
    errorScoll() {
      let errEle = document.querySelector('.is-invalid')
      errEle ? this.animateScroll(errEle, 50) : ''
    },
    animateScroll(element, speed) {
      //获取元素相对窗口的top值，此处应加上窗口本身的偏移
      let top = window.pageYOffset + element.getBoundingClientRect().top
      // 获取当前距离窗口的top值
      let osTop = document.documentElement.scrollTop || document.body.srcollTop
      let requestId
      let currentNumber = 0
      //采用requestAnimationFrame，平滑动画
      function step() {
        if (osTop >= top) {
          currentNumber = osTop -= speed
          window.scrollTo(0, osTop)
          requestId = window.requestAnimationFrame(step)
        } else {
          window.cancelAnimationFrame(requestId)
        }
      }
      window.requestAnimationFrame(step)
    },
    handleBack() {
      this.$router.go(0)
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    refreshCode() {
      // this.identifyCode = ''
      // this.makeCode(this.identifyCodes, 6)
      this.reloadCaptcha()
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
      }
    },
    formatIdentifyCode(e) {
      return String(e).substring(0, 6)
    }
  },

  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  beforeCreate() {},
  created() {},
  mounted() {
    let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
    let slug = path.replace(`dynamic-form/`, '')
    const { preview, id } = this.$route.query
    formData({ slug, preview, id }).then(res => {
      this.formData = res.data
    }),
      (this.identifyCode = '')
    // this.makeCode(this.identifyCodes, 6)
    this.reloadCaptcha()
    this.fetchData()
    this.isMounted = true
  },
  computed: {
    ...mapState('common', ['page'])
  },
  head() {
    return {
      title: '大豐銀行',
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: 'My custom description'
        }
      ]
    }
  }
}
</script>
