<template>
  <b-card>
    <b-form-group
      :name="data.metaKey"
      :label="data.config.label"
      label-cols-sm="4"
      label-cols-lg="2"
      :class="{ required: data.config.required }"
    >
      <b-form-file
        ref="file-input"
        v-model="data.answer"
        :accept="data.fileType"
        :name="data.key"
        @change="attachmentHandleChange"
      />
    </b-form-group>
  </b-card>
</template>

<script>
// import { uploadFile } from '@/api/frontend/form'
export default {
  name: 'form-attachment',
  props: {
    data: {
      type: Object
    },
    id: {
      type: String
    },
    readonly: {
      type: Boolean
    }
  },
  data() {
    return {
      isVerify: true
    }
  },
  computed: {
    combinedData: {
      get() {
        return this.data.answer
      },
      set(newValue) {
        // this.data.todo = newValue
        this.data.answer = newValue
      }
    }
  },
  methods: {
    async uploadFiles(file) {
      let id = this.id
      let key = this.data.key
      let formData = new FormData()
      for (var item in file) {
        formData.append(item, file[item])
      }
      formData.append('file', file)
      await uploadFile({ id, key }, formData).then(resp => {
        this.data.answer = resp.data.data
      })
    },
    attachmentHandleChange(ev) {
      const file = ev.target.files[0]
      var type = file.name
        .split('.')
        .pop()
        .toLowerCase()
      if (file) {
        if (this.data.fileType.indexOf(type) !== -1) {
          if (file.size <= this.data.limitFileSize) {
            this.uploadFiles(file)
            this.isVerify = true
          } else {
            this.$refs['file-input'].reset()
            this.isVerify = false
            this.$notify({
              group: 'tips',
              type: 'error',
              text: this.$t('上傳檔案最大為5MB')
            })
          }
        } else {
          this.$refs['file-input'].reset()
          this.$notify({
            group: 'tips',
            type: 'error',
            text: this.$t('上傳格式只接受pdf、png或jpeg')
          })
        }
      }
    },
    async isNotValid() {
      // return !this.isVerify
      let result = await this.$validator.validate()
      return !result
    }
  }
}
</script>
