<template>
  <b-card>
    <b-form-group :name="data.metaKey" :label="data.config.label" label-cols-sm="4" label-cols-lg="2" :class="{required: data.config.required }">
      <b-form-textarea
        v-model="data.answer"
        :name="data.metaKey"
        type="text"
      />
    </b-form-group>
  </b-card>
</template>

<script>
export default {
  name: 'form-textarea',
  props: {
    data: {
      type: Object
    }
  },
  computed: {
    combinedData: {
      get() {
        return this.data.answer
      },
      set(newValue) {
        this.data.answer = newValue
      }
    }
  },
  methods: {
    async isNotValid() {
      let result = await this.$validator.validate()
      return !result
    },
    validateMessage() {
      return '不能為空'
    }
  }
}
</script>
