<template>
  <b-card class="border-0">
    <b-form-group :name="data.metaKey">
      <!-- <b-form-fieldset class="mb-2" v-html="data.config.label"></b-form-fieldset> -->
      <div class="text-dark">
        <b-form-checkbox
        v-model="data.answer"
        :name="data.metaKey"
      >
      <div :class="{required: data.config.required }" v-html="data.config.label"></div>
      </b-form-checkbox>
      </div>
    </b-form-group>
  </b-card>
</template>

<script>
export default {
  name: 'form-select',
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      options: [
        {text: "是", value: true},
        {text: "否", value: false}
        ]
    }
  },
  computed: {
    changeState() {
      return this.data.answer
    },
    combinedData: {
      get() {
        return this.data.answer
      },
      set(newValue) {
        this.data.answer = newValue
      }
    }
  },
  methods: {
    // checkbox單選
    checkboxRadio(event, old) {
      console.log(event, old)
      for (var i = 1; i < this.data.options.length + 1; i++) {
        // console.log(document.getElementsByName('fieid16'))
        // document.fieid16[i].checked = false
        // this.data.options[i].checked = false
        document.getElementsByName(this.data.key)[i].checked = false
      }
      // event.checked = true
      return true
    },
    hideView(isHide) {
      if (isHide) {
        this.data.answer = '-'
      } else {
        this.data.answer = ''
      }
      this.isShow = !isHide
    },
    async isNotValid() {
      let result = await this.$validator.validate()
      return !result
    },
    validateMessage() {
      return '必需填寫'
    }
  }
}
</script>