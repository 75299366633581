<template>
<b-card>
  <b-form-group :name="data.metaKey" :label="data.config.label" label-cols-sm="4" label-cols-lg="2" :class="{ required: data.config.required }">
    <!-- <b-form-fieldset class="mb-2" :class="{required: data.config.required }" v-html="data.config.label"></b-form-fieldset> -->

    <div class="text-dark">
      <template v-if="data.config.expanded">
        <template v-if="data.config.multiple">
          <b-form-checkbox-group :options="data.config.choices" v-model="data.answer" :name="data.metaKey" v-validate="{
                required: data.config.required ? data.config.required : false,
              }" :class="errors.has(`${data.metaKey}`) && 'is-invalid'">
          </b-form-checkbox-group>
        </template>
        <template v-else>
          <b-form-radio-group :options="data.config.choices" v-model="data.answer" :name="data.metaKey" v-validate="{
                required: data.config.required ? data.config.required : false,
              }" :class="errors.has(`${data.metaKey}`) && 'is-invalid'"></b-form-radio-group>
        </template>
      </template>
      <template v-else>
        <b-form-select v-model="data.answer" :options="data.config.choices" :name="data.metaKey" v-validate="{
              required: data.config.required ? data.config.required : false,
            }" :class="errors.has(`${data.metaKey}`) && 'is-invalid'">
          <!-- <template v-slot:first>
              <b-form-select-option value="" disabled selected>{{$t("請選擇")}}</b-form-select-option>
            </template> -->
        </b-form-select>
      </template>
      <b-form-invalid-feedback>
        {{ errors.first(`${data.metaKey}`) }}
      </b-form-invalid-feedback>
    </div>
  </b-form-group>
</b-card>
</template>

<script>
export default {
  name: 'form-select',
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      isShow: true,
    }
  },
  computed: {
    changeState() {
      return this.data.answer
    },
    combinedData: {
      get() {
        return this.data.answer
      },
      set(newValue) {
        this.data.answer = newValue
      },
    },
  },
  created() {
    // this.data.answer = ""
    if(!this.data.config.expanded){
      this.data.config.choices.unshift({
        text: this.$t("請選擇"),
        value: "",
        disabled: this.data.config.required? true:false
      })
    }
  },
  methods: {
    // checkbox單選
    checkboxRadio(event, old) {
      console.log(event, old)
      for (var i = 1; i < this.data.options.length + 1; i++) {
        // console.log(document.getElementsByName('fieid16'))
        // document.fieid16[i].checked = false
        // this.data.options[i].checked = false
        document.getElementsByName(this.data.key)[i].checked = false
      }
      // event.checked = true
      return true
    },
    hideView(isHide) {
      if (isHide) {
        this.data.answer = '-'
      } else {
        this.data.answer = ''
      }
      this.isShow = !isHide
    },
    async isNotValid() {
      let result = await this.$validator.validate()
      return !result
    },
    validateMessage() {
      return '必需填寫'
    },
  },
}
</script>
